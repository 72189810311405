import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import SvgSearch from '../../icons/SvgSearch';
import {
  getBlur,
  getFocus,
  toCamelCase,
  unlimitedHelper,
} from '../../uikit/helper';
import Loader from '../../uikit/Loader/Loader';
import Button from '../../uikit/Button/Button';
import { Card, Modal } from '../../uikit';
import Flex from '../../uikit/Flex/Flex';
import InputText from '../../uikit/InputText/InputText';
import Tabel from '../../uikit/Table/Table';
import Text from '../../uikit/Text/Text';
import SelectTag from '../../uikit/SelectTag/SelectTag';
import LinkWrapper from '../../uikit/Link/LinkWrapper';
import Toast from '../../uikit/Toast/Toast';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import SvgLocation from '../../icons/SvgLocation';
import SvgNewTab from '../../icons/SvgNewTab';
import SvgRefresh from '../../icons/SvgRefresh';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import { AppDispatch, RootState } from '../../store';
import ProfileView from '../applicantpipelinemodule/ProfileView';
import { CANCEL, ERROR_MESSAGE } from '../constValue';
import { LINK } from '../../uikit/Colors/colors';
import YesOrNoModal from '../common/YesOrNoModal';
import Totalcount from '../../globulization/TotalCount';
import { jdMatchMiddleWare } from '../applicantprofilemodule/store/middleware/applicantProfileMiddleware';
import { dashBoardMiddleWare } from '../dashboardmodule/empdashboard/store/dashboardmiddleware';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { zitaMatchProgressMiddleWare } from '../zitamatchcandidatemodule/store/middleware/zitamatchcandidatemiddleware';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import Tour from '../tour/tour';
import Highlighter from '../tour/highlighter';
import { tourdataget } from '../tour/store/middleware/middleware';
import styles from './candidatedatabasetab.module.css';
import ApplicantDatabase from './applicantDatabase';
import {
  ApplicantSampledata,
  ApplicantmappedData,
  applicantTable,
} from './uploadedCandidateTable';
// import { EmpPoolEntity } from './bulkImportTypes';

import {
  bulkImportMiddleWare,
  bulkuploadedCandidatesMiddleWare,
  bulkuploadedParsingMiddleWare,
  uploadedProfileViewMiddleWare,
  bulkImportMatchMiddleWare,
} from './store/middleware/bulkImportMiddleware';
import ParsingLoadingModal from './ParsingLoadingModal';
import ProfileViewModal from './ProfileViewModal';
import CandidateDatabaseTabFilter from './CandidateDatabaseTabFilter';
import ApplicantsTabFilter from './ApplicantsTabFilter';

type Tabs = 'total' | 'completed' | 'inCompleted';
export type FormProps = {
  searchValue: string;
  jd_id: string;
  parser: string;
};
const initial: FormProps = {
  searchValue: '',
  jd_id: '',
  parser: '',
};

type Props = {
  emp_pool: any;
  params: any;
  total_count: number;
  completed: number;
  incompleted: number;
  searchValue: string;
  searchHandleChange: (a: any) => void;
  setKey: (arg: string) => void;
  features_balance: number;
  setFeaturesBalance: (a: number | null) => void;
  jd_id: any;
  setPageNumber: (a: number) => void;
  pageNumber: number;
  upDateloader: boolean;
  isjd_iddata?: any;
  setjd_iddata?: any;
  isBulkTab?: any;
  istableloadertrue?: boolean;
};

const ApplicantsTab = ({
  emp_pool,
  total_count,
  completed,
  incompleted,
  searchValue,
  jd_id,
  searchHandleChange,
  setKey,
  features_balance,
  setFeaturesBalance,
  setPageNumber,
  pageNumber,
  upDateloader,
  isjd_iddata,
  setjd_iddata,
  isBulkTab,
  params,
  istableloadertrue,
}: Props) => {
  const [isParse, setParse] = useState(false);
  const [isTableLoader, setTableLoader] = useState(false);
  const [isImport, setImport] = useState<any>('');
  const [isjdname, setjdname] = useState<any>();
  const [isJdId, setJdId] = useState<string>('0');
  const [isCanId, setCanId] = useState<any>([]);
  const [isJob, setJob] = useState<any>([]);
  const [isUrl, setUrl] = useState<string>('');
  const [isFile, setFile] = useState<string>('');
  const [isSearch, setSearch] = useState(1);
  const [isOpenProfile, setOpenProfile] = useState(false);
  const [isUpgrade, setUpgrade] = useState(false);
  const [isProfileView, setProfileView] = useState(false);
  const [isLoad, setLoad] = useState(false);
  const [isTab, setTab] = useState<Tabs>('total');
  const [isCandiTableLoader, setCandiTableLoader] = useState(false);
  const [isPageTab, setPageTab] = useState(total_count);
  const dispatch: AppDispatch = useDispatch();
  const [model, setmodel] = useState(false);
  const [withoutjderror, setwithoutjderror] = useState(false);
  const [verify, setverify] = useState(false);
  const [ispagecount, setpagecount] = useState<any>(null);
  //subcription setstate
  const [isopensubcription, setopensubcription] = useState(false);
  const [isSampleData, setSampleData] = useState(false);
  const [componentHeight, setComponentHeight] = useState(
    window.innerHeight - 395,
  );

  useEffect(() => {
    dispatch(SubsriptionMiddleWare());
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 395);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const {
    current_plan,
    current_resume_count,
    data,
    import_applicants,
    Permission1,
    super_user,
    status,
    explore,
    user,
    pipelineData,
    candidate_location } = useSelector(
      ({
        SubscriptionReducers,
        zitaMatchprogressReducers,
        permissionReducers,
        TourdataReducer,
        userProfileReducers,
        pipelinePageReducers,
        bulkUploadedCandidatesReducers
      }: RootState) => {
        return {
          current_plan: SubscriptionReducers.current_plan,
          current_resume_count: SubscriptionReducers.current_resume_count,
          data: zitaMatchprogressReducers.data,
          import_applicants: SubscriptionReducers.import_applicants,
          Permission1: permissionReducers.Permission,
          super_user: permissionReducers.super_user,
          explore: TourdataReducer.is_first_login,
          status: TourdataReducer.is_steps,
          user: userProfileReducers.user,
          pipelineData: pipelinePageReducers.pipeline,
          candidate_location: bulkUploadedCandidatesReducers.candidate_location
        };
      },
    );

  useEffect(() => {
    setpagecount(import_applicants);
  }, [import_applicants]);

  // Profile View Function
  const hanldeEditProfileView = (id: number) => {
    setCanId(id);
    setOpenProfile(true);
    setProfileView(false);
    dispatch(uploadedProfileViewMiddleWare({ id })).then((res) => {
      setFile(res.payload.resume_file_path);
    });
  };

  const hanldeProfileView = (id: number) => {
    setCanId(id);
    setProfileView(false);
    setOpenProfile(true);
    dispatch(uploadedProfileViewMiddleWare({ id })).then((res) => {
      setFile(res.payload.resume_file_path);
    });
  };
  const handleSubmitWithJd = (values: FormProps) => {
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        search: searchValue,
        jd_id: isJdId,
        pagecount: ispagecount,
      }),
    )
      .then((response) => {
        setSearch(response.payload.search);
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  const formik = useFormik({
    initialValues: initial,
    onSubmit: handleSubmitWithJd,
    enableReinitialize: true,
  });

  const handleCompletedWithJd = () => {
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        completed,
        search: searchValue,
        jd_id: isJdId,
        page: pageNumber + 1,
        pagecount: ispagecount,
      }),
    )
      .then(() => {
        getFocus('candidates__input');
        setPageNumber(0);
        getBlur('candidates__input');
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  const handleInCompeleted = () => {
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        incompleted,
        search: formik.values.searchValue,
        jd_id: isJdId,
        page: pageNumber + 1,
        pagecount: ispagecount,
      }),
    )
      .then(() => {
        getFocus('candidates__input');
        setPageNumber(0);
        getBlur('candidates__input');
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };
  const handleTotal = () => {
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        total: total_count,
        search: formik.values.searchValue,
        jd_id: isJdId,
        page: pageNumber + 1,
        pagecount: ispagecount,
      }),
    )
      .then(() => {
        getFocus('candidates__input');
        setPageNumber(0);
        getBlur('candidates__input');
      })
      .catch(() => {
        Toast(ERROR_MESSAGE, 'LONG', 'error');
      });
  };

  if (isJdId === '0') {
    emp_pool = [];
    total_count = 0;
    completed = 0;
    incompleted = 0;
  }
  const columns = useMemo(
    () =>
      applicantTable(
        setFeaturesBalance,
        hanldeEditProfileView,
        searchValue,
        isTab,
        isJdId,
        total_count,
        completed,
        incompleted,
        pageNumber,
      ),
    [emp_pool],
  );
  useEffect(() => {
    dispatch(zitaMatchProgressMiddleWare({}));
    if (data === true) {
      localStorage.setItem('bulk_loader', 'true');
    }
    if (data === false) {
      localStorage.setItem('bulk_loader', 'false');
    }
  }, [data]);
  useEffect(() => {
    if (isTab === 'total') {
      setPageTab(total_count);
    }
    if (isTab === 'completed') {
      setPageTab(completed);
    }
    if (isTab === 'inCompleted') {
      setPageTab(incompleted);
    }
  }, [isTab, upDateloader]);

  // Pagination
  const usersPerPage = import_applicants;
  const pageCounts = Math.ceil(total_count / usersPerPage);

  const handleCloseParse = () => {
    setParse(false);
    localStorage.setItem('bulk_loader', 'true');
  };

  const handleOpenParse = () => setParse(true);

  useEffect(() => {
    formik.setFieldValue('jd_id', isJdId);
  }, [isJdId]);

  // Pagination Function
  const handleSetPagination = (a: number, ispagecounts?: number) => {
    setPageNumber(a);
    setTableLoader(true);
    if (isTab === 'total') {
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          page: a + 1,
          total: total_count,
          jd_id: isJdId,
          search: searchValue,
          pagecount: ispagecounts,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
    if (isTab === 'total') {
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          page: a + 1,
          total: total_count,
          jd_id: isJdId,
          search: searchValue,
          pagecount: ispagecounts,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
    if (isTab === 'inCompleted') {
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          page: a + 1,
          incompleted,
          jd_id: isJdId,
          search: searchValue,
          pagecount: ispagecounts,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
    if (isTab === 'completed') {
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          page: a + 1,
          completed,
          jd_id: isJdId,
          search: searchValue,
          pagecount: ispagecounts,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
  };

  useEffect(() => {
    if (searchValue === '' && isJdId !== '0' && isJdId !== undefined) {
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          page: 1,
          jd_id: isJdId !== undefined ? isJdId : '0',
          pagecount: ispagecount,
        }),
      ).then(() => {
        getFocus('candidates__input');
        setPageNumber(0);
        getBlur('candidates__input');
        setCandiTableLoader(false);
      });
    }
  }, [searchValue]);

  // Table Refresh Function
  const hanldeSvgRefresh = (e: any) => {
    setCandiTableLoader(true);
    formik.setFieldValue('searchValue', '');
    setTab('total');
    e.preventDefault();
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        page: 1,
        jd_id: isJdId !== undefined ? isJdId : '',
        pagecount: ispagecount,
      }),
    ).then(() => {
      getFocus('candidates__input');
      setPageNumber(0);
      getBlur('candidates__input');
      setCandiTableLoader(false);
    });
    dispatch(zitaMatchProgressMiddleWare({}));
  };

  // const hanldeMatch = () => {
  //   setCandiTableLoader(true);
  //   const formData = new FormData();
  //   formData.append('jd_id', isJdId);
  //   dispatch(jdMatchMiddleWare({ jd_id: isJdId })).then(() => {
  //     dispatch(
  //       bulkuploadedCandidatesMiddleWare({ page: 1, jd_id: isJdId !== undefined ? isJdId : '', pagecount: ispagecount }),
  //     ).then(() => {
  //       setPageNumber(0);
  //       setCandiTableLoader(false);
  //     });
  //   });
  // };

  // Bulk Upload Parsing Function
  const hanldeParsing = () => {
    dispatch(bulkuploadedParsingMiddleWare({ parser: '1' })).then(
      (response) => {
        dispatch(
          bulkuploadedCandidatesMiddleWare({
            page: 1,
            jd_id: isJdId,
            pagecount: ispagecount,
          }),
        ).then(() => {
          setPageNumber(0);
          if (
            response.payload?.success === true &&
            response.payload?.compeleted_profile !== 0
          ) {
            // dispatch(
            //   jdMatchMiddleWare({
            //     jd_id: isJdId,
            //     count: response.payload.compeleted_profile,
            //   }),
            // );
          }
        });
        dispatch(zitaMatchProgressMiddleWare({})).then((resss) => {
          localStorage.setItem('bulk_loader', 'false');
        });
        dispatch(bulkImportMiddleWare()).then((res) => {
          setFeaturesBalance(res.payload.features_balance);
        });
        setImport(localStorage.setItem('bulk_loader', 'false'));
        localStorage.setItem('isImport', 'true');
        setParse(false);
      },
    );
    // .then(() => {
    //   dispatch(jdMatchMiddleWare({ jd_id: isJdId }))
    // })
  };

  // Bulk Upload Parsing Function
  const hanldeApplicant = (jdId: string, optional?: any) => {
    if (optional === false) {
      setLoad(true);
    }
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        page: 1,
        jd_id: jdId,
        pagecount: ispagecount,
      }),
    ).then((res) => {
      setJob(res.payload.job);
      setUrl(res.payload.career_url);
      setSearch(res.payload.search);
      setPageNumber(0);
      setLoad(false);
    });
  };

  // they update the jd_id details while navigatting from notification section and job view page
  useEffect(() => {
    if (isjd_iddata !== 0 && isjd_iddata !== null) {
      setJdId(
        jd_id
          ?.filter((dat) => dat?.id === Number(isjd_iddata))[0]
          ?.id?.toString(),
      );
      hanldeApplicant(
        jd_id
          ?.filter((dat) => dat?.id === Number(isjd_iddata))[0]
          ?.id?.toString(),
        true,
      );
      // setTimeout(() => setjd_iddata(''), 2000);
    }
  }, [isjd_iddata,jd_id]);

  const manageUser = () => {
    // window.location.replace()
    window.location.replace(
      window.location.origin + '/account_setting/subscription?planFocus=focus',
    );
    // history.push('/account_setting/settings?planFocus=focus');
  };

  const handleCloseImportModal = () => {
    setImport(localStorage.setItem('isImport', 'false'));
  };

  useEffect(() => {
    setImport(localStorage.getItem('isImport'));
  });

  const hanldeSvgRefreshOnUpdate = (e: any) => {
    setOpenProfile(false);
    setCandiTableLoader(true);
    formik.setFieldValue('searchValue', '');
    setTab('total');
    e.preventDefault();
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        page: 1,
        jd_id: isJdId,
        pagecount: ispagecount,
      }),
    ).then(() => {
      getFocus('candidates__input');
      setPageNumber(0);
      getBlur('candidates__input');
      setCandiTableLoader(false);
    });
  };
  const handleopenmodal = () => {
    if (Number(isJdId) !== 0) {
      setmodel(true);
    }
    if (Number(isJdId) === 0) {
      setwithoutjderror(true);
    }
  };
  useEffect(() => {
    if (isJdId) {
      setwithoutjderror(false);
    }
  }, [isJdId]);
  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      formik.handleSubmit();
    }
  };
  const update = () => {
    setverify(false);
  };

  const handlefunction = () => {
    setverify(true);
    formik.setFieldValue('parser', '0');
  };
  const handlefunction1 = () => {
    setverify(true);
    formik.setFieldValue('parser', '1');
  };
  const closemodel = () => {
    setverify(false);
    setmodel(false);
  };
  const isBulkLoaderprocess = localStorage.getItem('bulk_loader');
  const value = emp_pool?.length;
  const value1 = value > 4;
  const formattedJobTitle = isJob?.job_title?.replace(/\s+/g, '-');
  const InputJDID =
    isjd_iddata?.length !== 0
      ? jd_id?.filter((dat) => dat?.id === Number(isjd_iddata))
      : '';
  const [step6, setstep6] = useState(false);
  const [currentindex, setcurrentindex] = useState(0);
  const history = useHistory();
  useEffect(() => {
    dispatch(tourdataget({}));
    if (status) {
      setstep6(true);
    }
  }, [status]);

  return (
    <Flex className={styles.Applicantdatabase}>
      {status && (
        <Highlighter left={'38px'} top={'300px'} text={'Add New Applicants'} />
      )}
      {step6 && (
        <Tour
          process={() => {
            if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '6/9';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '6/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '6/9';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '5/8';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '5/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post')
            ) {
              return '6/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '5/7';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '5/8';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '5/7';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '5/8';
            } else if (
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '4/7';
            } else if (Permission1.includes('manage_account_settings')) {
              return '5/7';
            } else if (Permission1.includes('create_post')) {
              return '5/7';
            } else if (Permission1.includes('bulkImport_candidates')) {
              return '4/6';
            } else if (Permission1.includes('talent_sourcing')) {
              return '4/7';
            } else {
              return '4/6';
            }
            return null;
          }}
          skiponclick={() => {
            setstep6(false);
            setcurrentindex(6);
            sessionStorage.setItem('JobsDropdown', '0');
            sessionStorage.setItem('ViewJobsDropdown', '0');
            sessionStorage.setItem('CandidateDropdown', '0');
            sessionStorage.setItem('CommunicationDropdown', '0');
            sessionStorage.setItem('BrandDropdown', '0');
            sessionStorage.setItem('MyAccountDropdown', '1');
            sessionStorage.setItem('SourcingCandidateDropdown', '0');
            history.push('/account_setting/profiles');
            dispatch(
              tourdataget({ skip_id: 6, restart_status: false, explore: 0 }),
            );
          }}
          previousonclick={() => {
            setstep6(false);
            if (Permission1.includes('bulkImport_candidates')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '1');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/bulk_import?tab=0');
            } else if (Permission1.includes('create_post')) {
              sessionStorage.setItem('JobsDropdown', '1');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              history.push('/jobs/create_your_job');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
            } else if (Permission1.includes('manage_account_settings')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '1');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/account_setting/build_careers_page');
            } else {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '1');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/account_setting/profiles');
            }
          }}
          nextonclick={() => {
            setstep6(false);
            if (Permission1.includes('talent_sourcing')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '1');
              history.push('/candidates_sourcing?tab=0');
            } else {
              if (super_user) {
                sessionStorage.setItem('JobsDropdown', '1');
                sessionStorage.setItem('ViewJobsDropdown', '0');
                sessionStorage.setItem('CandidateDropdown', '0');
                sessionStorage.setItem('CommunicationDropdown', '0');
                sessionStorage.setItem('BrandDropdown', '0');
                sessionStorage.setItem('MyAccountDropdown', '0');
                sessionStorage.setItem('SourcingCandidateDropdown', '0');
                sessionStorage.setItem('template', '1');
                sessionStorage.setItem('pipeline', '1');
                sessionStorage.setItem(
                  'wk_id',
                  pipelineData[0]?.wk_id.toString(),
                );
                history.push('/account_setting/hiring_workflow');
              } else {
                if (Permission1.includes('manage_account_settings')) {
                  sessionStorage.setItem('JobsDropdown', '1');
                  sessionStorage.setItem('ViewJobsDropdown', '0');
                  sessionStorage.setItem('CandidateDropdown', '0');
                  sessionStorage.setItem('CommunicationDropdown', '0');
                  sessionStorage.setItem('BrandDropdown', '0');
                  sessionStorage.setItem('MyAccountDropdown', '0');
                  sessionStorage.setItem('SourcingCandidateDropdown', '0');
                  sessionStorage.setItem('template', '1');
                  sessionStorage.setItem('pipeline', '1');
                  sessionStorage.setItem(
                    'wk_id',
                    pipelineData[0]?.wk_id.toString(),
                  );
                  history.push('/account_setting/hiring_workflow');
                } else {
                  sessionStorage.setItem('JobsDropdown', '1');
                  sessionStorage.setItem('ViewJobsDropdown', '0');
                  sessionStorage.setItem('CandidateDropdown', '0');
                  sessionStorage.setItem('CommunicationDropdown', '0');
                  sessionStorage.setItem('BrandDropdown', '0');
                  sessionStorage.setItem('MyAccountDropdown', '0');
                  sessionStorage.setItem('SourcingCandidateDropdown', '0');
                  sessionStorage.setItem('template', '1');
                  sessionStorage.setItem('pipeline', '1');
                  sessionStorage.setItem(
                    'wk_id',
                    pipelineData[0]?.wk_id.toString(),
                  );
                  history.push('/account_setting/hiring_workflow');
                }
              }
            }
          }}
          borderTop
          previous
          types={'arrowLeftTop'}
          top={'282px'}
          left={'205px'}
          text={
            '<div style="font-weight: bold">Step into \'Add Applicants\': the portal for linking talents to specific roles.</div><div style="margin-top:10px;">Zita ATS allows you to swiftly add <div style="font-weight: bold; display:contents">applicants</div> into your <div style="font-weight: bold; display:contents">hiring pipeline.</div></div><div style="margin-top:10px;">Centralize your prospects with each resume paving the way to your next team addition, matched perfectly to job vacancies.</div>'
          }
          // text={"Step into 'Add Applicants'—your gateway to building a strong talent pool. Here in Zita ATS, you can effortlessly import resumes, populating your candidate database with ease. Imagine the simplicity of having all your potential hires stored centrally, each resume a doorway to a possible new team member, seamlessly connected to relevant job openings."}
        />
      )}
      <YesOrNoModal
        title={
          <Text style={{ width: 580, marginLeft: 12 }}>
            Your available candidates limit is{' '}
            {unlimitedHelper(features_balance)}. You are importing more resumes
            than your available candidates limit. Please change your plan to
            proceed further.
          </Text>
        }
        btnLeftOnclick={() => setUpgrade(false)}
        btnRightOnclick={manageUser}
        btnLeftTitle={CANCEL}
        btnRightTitle={'Upgrade'}
        open={isUpgrade}
      />
      {isOpenProfile && (
        <ProfileViewModal
          filePath={isFile}
          open={isOpenProfile}
          canId={isCanId}
          jdId={isJdId}
          openview={true}
          hanldeProfileView={hanldeProfileView}
          // cancel={() => setOpenProfile(false)}
          refreshOnUpdate={(e: any) => hanldeSvgRefreshOnUpdate(e)}
        />
      )}

      <ProfileView
        open={isProfileView}
        cancel={() => setProfileView(false)}
        jobId={isJdId}
        // bulkId={isCanId}
        candidateId={isCanId.toString()}
        inviteIconNone={true}
        applicantpipeline={false}
        // hanldeEditProfileView={hanldeEditProfileView}
      />
      <ParsingLoadingModal
        loader
        title={'Parsing and loading resumes in background.....'}
        open={isParse}
        close={handleCloseParse}
        des={
          <Text className={styles.des}>
            {`This might take a few minutes to process the files. We'll notify you
            by email when completed. Moving away from this page will not stop
            the process.`}
          </Text>
        }
      />

      <ParsingLoadingModal
        info
        css
        title={'Resumes imported successfully....'}
        open={isImport === 'true' ? true : false}
        close={handleCloseImportModal}
        des={
          <Text>
            Your available candidates limits are{' '}
            {unlimitedHelper(features_balance)}. Please check on the{' '}
            <Text
              onClick={() => {
                setKey('2');
                handleCloseImportModal();
              }}
              color="link"
              bold
            >
              log file
            </Text>{' '}
            to get the complete import details.
          </Text>
        }
      />

      <Modal open={model}>
        <Flex
          className={verify === true ? styles.bulkmodel : styles.verifymodel}
          style={{ height: '330px' }}
        >
          <ApplicantDatabase
            setmodel={setmodel}
            verifymodel={update}
            hanldeParsing={hanldeParsing}
            isjdId={isJdId}
            setParse={handleOpenParse}
            isBulkLoader={localStorage.getItem('bulk_loader')}
            setUpgrade={setUpgrade}
            candidatesLimit={features_balance}
            formik={formik.values.parser}
            current_resume_count={features_balance}
          />
        </Flex>
      </Modal>

      <Flex marginTop={10} marginLeft={4}>
        <Text>
          Start by selecting a job to add candidates to upload resumes directly.
        </Text>
      </Flex>

      <Flex marginTop={10} marginLeft={4}>
        <ApplicantsTabFilter
          isJdId={isJdId}
          candidate_location={candidate_location}
          ispagecount={ispagecount}
          pageNumber={pageNumber}
          setCandiTableLoader={setCandiTableLoader}
        />
      </Flex>

      <Flex row between className={styles.inputConatinerApplicants}>
        <Flex row>
          <Text className={styles.importText}>Import applicants for*</Text>
          <Flex row>
            <Flex>
              <Flex className={styles.skillContainer}>
                <SelectTag
                  labelBold
                  options={jd_id}
                  value={InputJDID}
                  noOptionsMessage={({}) => 'No active jobs'}
                  placeholder="Select a job to import applicants"
                  getOptionValue={(option: { id: number }) => `${option.id}`}
                  getOptionLabel={(option: { job_title: string }) =>
                    toCamelCase(option.job_title)
                  }
                  onChange={(option) => {
                    setjd_iddata(option.id);
                    setJdId(option.id.toString());
                    hanldeApplicant(option.id.toString(), false);
                  }}
                />
              </Flex>{' '}
              {withoutjderror && (
                <Text color="error">Select a job to import applicants</Text>
              )}
            </Flex>
            <InputText
              className={styles.inputWidth}
              inputConatinerClass={styles.inputStyle}
              placeholder={'Search applicants by name or email'}
              value={searchValue}
              onChange={searchHandleChange}
              id={'applicant__input'}
              actionRight={() => (
                <label style={{ margin: 0, marginTop: '3px' }}>
                  <SvgSearch width={14} height={14} />
                </label>
              )}
              onKeyPress={handleKeyPress}
            />

            <Button
              id="applicant__buttonFind"
              disabled={searchValue === '' ? true : false}
              onClick={formik.handleSubmit}
            >
              {/* Find Applicants */}
              Search
            </Button>

            {isUrl?.length > 0 && (
              <Flex>
                <LinkWrapper
                  target={'_blank'}
                  to={`/${isUrl}/career_job_view/${isJob?.id}/${formattedJobTitle}`}
                >
                  <Flex row>
                    <Button
                      types="secondary"
                      className={styles.viewText}
                      style={{ marginTop: '0px' }}
                    >
                      View Job
                    </Button>
                  </Flex>
                </LinkWrapper>
              </Flex>
            )}
          </Flex>
        </Flex>

        {isBulkLoaderprocess === 'true' ? (
          <Flex row>
            <Loader size="medium" withOutOverlay />
            <Text color="gray" style={{ marginLeft: 16 }}>
              Processing...
            </Text>
          </Flex>
        ) : (
          <>
            {current_resume_count === 0 ? (
              <Button
                onClick={() => setopensubcription(true)}
                className={styles.btnStyle}
                style={{ marginRight: '15px' }}
              >
                <Flex row>
                  <Flex style={{ cursor: 'pointer' }}>
                    <Text color="white"> Upload Resumes</Text>
                  </Flex>
                  <Flex
                    marginLeft={5}
                    marginTop={1}
                    style={{ cursor: 'pointer' }}
                  >
                    <SvgSubcriptioncrown height={14} width={14} fill="" />
                  </Flex>
                </Flex>
              </Button>
            ) : (
              <Flex>
                <Button
                  onClick={handleopenmodal}
                  style={{ marginRight: '15px' }}
                >
                  Upload Resumes
                </Button>
              </Flex>
            )}
          </>
        )}
      </Flex>

      {!isLoad && !istableloadertrue ? (
        <>
          <Flex row center className={styles.filterStyle}>
            <Flex row center className={styles.marginLeft}>
              {total_count === 0 ? (
                <Text
                  bold={isTab === 'total'}
                  className={styles.linkSpaceDefault}
                >
                  <Totalcount
                    name="Total Imported Applicants"
                    numbers={total_count}
                    click={total_count === 0 ? false : true}
                  />
                </Text>
              ) : (
                <Text
                  onClick={() => {
                    handleTotal();
                    setTab('total');
                  }}
                  bold={isTab === 'total'}
                  className={styles.linkSpace}
                  color={'link'}
                >
                  <Totalcount
                    name="Total Applicants"
                    numbers={total_count}
                    click={total_count === 0 ? false : true}
                  />
                </Text>
              )}
            </Flex>
            {total_count !== 0 && (
              <>
                <Flex row center className={styles.marginLeft}>
                  {completed === 0 ? (
                    <Text
                      bold={isTab === 'completed'}
                      className={styles.linkSpaceDefault}
                    >
                      <Totalcount
                        name="Completed Profiles"
                        numbers={completed}
                        click={completed === 0 ? false : true}
                      />
                    </Text>
                  ) : (
                    <Text
                      onClick={() => {
                        handleCompletedWithJd();
                        setTab('completed');
                      }}
                      bold={isTab === 'completed'}
                      color={'link'}
                      className={styles.linkSpace}
                    >
                      <Totalcount
                        name="Completed Profiles"
                        numbers={completed}
                        click={completed === 0 ? false : true}
                      />
                    </Text>
                  )}
                </Flex>

                <Flex row center className={styles.inComplete}>
                  {incompleted === 0 ? (
                    <Text
                      bold={isTab === 'inCompleted'}
                      className={styles.linkSpaceDefault}
                    >
                      <Totalcount
                        name=" Incomplete Profiles"
                        numbers={incompleted}
                        click={incompleted === 0 ? false : true}
                      />
                    </Text>
                  ) : (
                    <Text
                      color={'link'}
                      onClick={() => {
                        handleInCompeleted();
                        setTab('inCompleted');
                      }}
                      bold={isTab === 'inCompleted'}
                      className={styles.linkSpace}
                    >
                      <Totalcount
                        name=" Incomplete Profiles"
                        numbers={incompleted}
                        click={incompleted === 0 ? false : true}
                      />
                    </Text>
                  )}
                </Flex>
              </>
            )}
            {total_count > 0 && (
              <CustomTooltip title={'Refresh table'}>
                <div
                  tabIndex={-1}
                  role={'button'}
                  onKeyPress={() => {}}
                  className={styles.svgRefresh}
                  onClick={(e) => {
                    hanldeSvgRefresh(e);
                  }}
                >
                  <SvgRefresh />
                </div>
              </CustomTooltip>
            )}
          </Flex>
          {isCandiTableLoader ? (
            <Flex center middle height={100}>
              <Loader withOutOverlay size={'medium'} />
            </Flex>
          ) : emp_pool?.length === 0 &&
            !params.includes('search') &&
            !params.includes(
              'education_level',
              'work_experience',
              'location',
              'skill_match',
            ) ? (
            !isSampleData ? (
              <>
                <Flex style={{ opacity: 0.5, pointerEvents: 'none' }}>
                  <Tabel columns={ApplicantmappedData} />
                </Flex>
                <Flex middle center height={componentHeight}>
                  <Flex center>
                    <Flex center>
                      <Text
                        className={styles.postyet2}
                        size={13}
                        color="placeholder"
                      >
                        Your Talent pool is Empty
                      </Text>
                    </Flex>
                    <Flex center>
                      {InputJDID?.length === 0 ? (
                        <Text
                          className={styles.postyet3}
                          size={13}
                          color="placeholder"
                        >
                          Select a Job and Import candidates to build your
                          database.
                        </Text>
                      ) : (
                        <Text
                          className={styles.postyet3}
                          size={13}
                          color="placeholder"
                        >
                          Import candidates to build your database.
                        </Text>
                      )}
                    </Flex>
                    <Flex row middle>
                      <Text style={{ marginRight: '4px' }} color="placeholder">
                        To refer some sample Candidate details
                      </Text>
                      <Text
                        color="link"
                        bold
                        onClick={() => setSampleData(true)}
                      >
                        Click here
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            ) : (
              <>
                <Flex style={{ opacity: 0.5, pointerEvents: 'none' }}>
                  <Tabel
                    dataSource={ApplicantSampledata}
                    columns={ApplicantmappedData}
                  />
                </Flex>
                <Flex style={{ position: 'absolute', left: '46%', top: '67%' }}>
                  <Text color="link" bold onClick={() => setSampleData(false)}>
                    Go back
                  </Text>
                </Flex>
              </>
            )
          ) : (
            <Flex flex={1}>
              <Tabel
                empty={
                  (params.includes('search') && 'No candidate found') ||
                  (params.includes('education_level') &&
                    params.includes('work_experience') &&
                    params.includes('location') &&
                    params.includes('skill_match') &&
                    'No candidate found')
                }
                dataSource={emp_pool}
                columns={columns}
                isLoader={isTableLoader}
                pageCount={pageCounts}
                pageNumber={pageNumber}
                handleSetPagination={handleSetPagination}
                isCandiTableLoader={isCandiTableLoader}
                isPageTab={isPageTab}
                isBulkTab={isBulkTab}
                setpagecount={setpagecount}
                only_pagination={true}
                pagination_count={ispagecount}
              />
            </Flex>
          )}
        </>
      ) : (
        <Flex center middle height={100}>
          <Loader withOutOverlay size={'medium'} />
        </Flex>
      )}
      {isopensubcription && (
        <SubscriptionModal
          openmodel={isopensubcription}
          setopensubcription={setopensubcription}
          addon_name={['AI Resume Parsing Credits']}
          differece_key={['AI_matching']}
          purpose={
            <div>
              <strong>AI Resume Parsing Limit Reached:</strong> To continue
              viewing applicants, you must &apos;Upgrade your plan&apos; or
              &apos;Buy additional AI Resume Parsing Credits add-on&apos;.
            </div>
          }
          purpose_freetrial={
            <div>
              <strong>AI Resume Parsing Limit Reached:</strong> To continue
              viewing applicants, you must &apos;Upgrade your plan&apos;.
            </div>
          }
          purpose_addon={
            <div>
              <strong>AI Resume Parsing Limit Reached:</strong> To continue
              viewing applicants, you must &apos;Buy additional AI Resume
              Parsing Credits add-on&apos;.
            </div>
          }
        />
      )}
    </Flex>
  );
};

export default ApplicantsTab;
