import { useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store';
import Totalcount from '../../globulization/TotalCount';
import SvgSearch from '../../icons/SvgSearch';
import SvgSubcriptioncrown from '../../icons/Subscriptioncrown';
import {
  enterKeyPress,
  getBlur,
  getFocus,
  isEmpty,
  unlimitedHelper,
} from '../../uikit/helper';
import { Card, InputCheckBox, Modal, SelectTag } from '../../uikit';
import Loader from '../../uikit/Loader/Loader';
import Button from '../../uikit/Button/Button';
import Flex from '../../uikit/Flex/Flex';
import CustomTooltip from '../../uikit/Tooltip/tooltip';
import InputText from '../../uikit/InputText/InputText';
import Tabel from '../../uikit/Table/Table';
import Text from '../../uikit/Text/Text';
import Toast from '../../uikit/Toast/Toast';
import SvgRefresh from '../../icons/SvgRefresh';
// import ProfileView from '../applicantpipelinemodule/ProfileView';
import ZitaMatchCandidateDrawer from '../zitamatchcandidatemodule/ZitaMatchCandidateDrawer';
import { CANCEL, ERROR_MESSAGE } from '../constValue';
import YesOrNoModal from '../common/YesOrNoModal';
import { dashBoardMiddleWare } from '../dashboardmodule/empdashboard/store/dashboardmiddleware';
import SubscriptionModal from '../subscriptionmodule/subscriptionmoduleScreen';
import { zitaMatchProgressMiddleWare } from '../zitamatchcandidatemodule/store/middleware/zitamatchcandidatemiddleware';
import SvgFilterRefresh from '../../icons/SvgFilterRefresh';
import SvgArrowUp from '../../icons/SvgArrowUp';
import { SubsriptionMiddleWare } from '../navbar/empnavbar/store/navbarmiddleware';
import SvgClose from '../../icons/SvgClose';
import Tour from '../tour/tour';
import Highlighter from '../tour/highlighter';
import { tourdataget } from '../tour/store/middleware/middleware';
import styles from './candidatedatabasetab.module.css';
import CandidateDatabase from './CandidateDatabase';
import { title, Sampledata, columnsCandi } from './uploadedCandidateTable';
// import { EmpPoolEntity } from './bulkImportTypes';
import {
  bulkImportMiddleWare,
  bulkuploadedCandidatesMiddleWare,
  bulkuploadedParsingMiddleWare,
  uploadedProfileViewMiddleWare,
} from './store/middleware/bulkImportMiddleware';

import ParsingLoadingModal from './ParsingLoadingModal';
import ProfileViewModal from './ProfileViewModal';
import { experienceOption, qualificationOption } from './bulkImportScreenMock';
import CandidateDatabaseTabFilter from './CandidateDatabaseTabFilter';
import { qualificationFilterHelper } from './BulkimportHelper';

type Tabs = 'total' | 'completed' | 'inCompleted';

type Props = {
  handleTotal: () => void;
  handleSubmit: () => void;
  handleCompleted: () => void;
  handleInCompeleted: () => void;
  searchValue: string;
  searchHandleChange: (a: any) => void;
  setKey: (arg: string) => void;
  // features_balance: number;
  setFeaturesBalance: (a: number | null) => void;
  isSearch: number;
  formik: any;
  setPageNumber: (a: number) => void;
  pageNumber: number;
  isBulkTab: any;
};

const CandidateDatabaseTab = ({
  handleTotal,
  handleSubmit,
  handleCompleted,
  handleInCompeleted,
  searchValue,
  searchHandleChange,
  setKey,

  setFeaturesBalance,
  isSearch,
  formik,
  setPageNumber,
  pageNumber,
  isBulkTab,
}: Props) => {
  //subcription setstate
  const [isopensubcription, setopensubcription] = useState(false);
  const history = useHistory();
  const dropDownRef = useRef(null);
  const myRef = useRef<any>();
  const selectInputRef = useRef<any>();
  const [isParse, setParse] = useState(false);
  const [isTableLoader, setTableLoader] = useState(false);
  const [isImport, setImport] = useState<any>('');
  const [isFile, setFile] = useState<string>('');
  // const [isPersonal, setPersonal] = useState<any>([]);
  // const [isAddDetail, setAddDetail] = useState<any>([]);
  const [isProfileView, setProfileView] = useState(false);
  const [isOpenProfile, setOpenProfile] = useState(false);
  const [isUpgrade, setUpgrade] = useState(false);
  const [isTab, setTab] = useState<Tabs>('total');
  const [isCandiTableLoader, setCandiTableLoader] = useState(false);
  const [isCanId, setCanId] = useState<any>([]);
  const [ispagecount, setpagecount] = useState<any>(null);
  const dispatch: AppDispatch = useDispatch();
  const [model, setmodel] = useState(false);
  const [Relocate, setRelocate] = useState(false);
  const [verify, setverify] = useState(false);

  const [showDropDown, setShowDropDown] = useState(false);
  const [change, setchange] = useState(false);
  const [location, setlocation] = useState('');
  const [experience, setexperience] = useState('');
  const [isBachelors, setBachelors] = useState(false);
  const [isDoctorate, setDoctorate] = useState(false);
  const [isDiploma, setDiploma] = useState(false);
  const [isMasters, setMasters] = useState(false);
  const [isAny, setAny] = useState(true);
  const [isOther, setOther] = useState(false);
  const [isSampleData, setSampleData] = useState(false);
  const [componentHeight, setComponentHeight] = useState(
    window.innerHeight - 355,
  );

  // const history=useHistory()
  // Profile View Function

  //  const hanldeEditProfileView = (id: number) => {
  //   setCanId(id);
  //   setOpenProfile(false);
  //   setProfileView(true);
  //    dispatch(uploadedProfileViewMiddleWare({ id })).then((res) => {
  //     setFile(res.payload.resume_file_path);
  //   });
  // };
  useEffect(() => {
    const handleResize = () => {
      setComponentHeight(window.innerHeight - 355);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const hanldeEditProfileView = (id: number) => {
    setCanId(id);
    setProfileView(false);
    setOpenProfile(true);
    dispatch(uploadedProfileViewMiddleWare({ id })).then((res) => {
      setFile(res.payload.resume_file_path);
    });
  };

  useEffect(() => {
    if (searchValue === '') {
      dispatch(
        bulkuploadedCandidatesMiddleWare({ pagecount: import_candidates }),
      );
    }
  }, [searchValue]);

  const handlechange = () => {
    setmodel(true);
    dispatch(dashBoardMiddleWare());
  };

  const {
    emp_pool,
    total_count,
    completed,
    incompleted,
    features_balance,
    upDateloader,
    current_plan,
    current_resume_count,
    data,
    import_candidates,
    params,
    Permission1,
    super_user,
    status,
    explore,
    candidate_location,
  } = useSelector(
    ({
      bulkUploadedCandidatesReducers,
      bulkImportReducers,
      dashboardEmpReducers,
      SubscriptionReducers,
      zitaMatchprogressReducers,
      permissionReducers,
      TourdataReducer,
    }: RootState) => {
      return {
        emp_pool: bulkUploadedCandidatesReducers.emp_pool,
        total_count: bulkUploadedCandidatesReducers.total_count,
        completed: bulkUploadedCandidatesReducers.completed,
        incompleted: bulkUploadedCandidatesReducers.incompleted,
        features_balance: bulkImportReducers.features_balance,
        upDateloader: bulkUploadedCandidatesReducers.isLoading,
        current_plan: SubscriptionReducers.current_plan,
        current_resume_count: SubscriptionReducers.current_resume_count,
        data: zitaMatchprogressReducers.data,
        import_candidates: SubscriptionReducers.import_candidates,
        params: bulkUploadedCandidatesReducers.params,
        Permission1: permissionReducers.Permission,
        super_user: permissionReducers.super_user,
        explore: TourdataReducer.is_first_login,
        status: TourdataReducer.is_steps,
        candidate_location: bulkUploadedCandidatesReducers.candidate_location,
      };
    },
  );
  useEffect(() => {
    setpagecount(import_candidates);
  }, [import_candidates]);

  const [isPageTab, setPageTab] = useState(total_count);

  const columns = useMemo(
    () =>
      title(
        hanldeEditProfileView,
        setFeaturesBalance,
        searchValue,
        isTab,
        total_count,
        completed,
        incompleted,
        pageNumber,
        emp_pool,
      ),
    [emp_pool],
  );

  useEffect(() => {
    dispatch(zitaMatchProgressMiddleWare({}));
  }, []);

  useEffect(() => {
    if (isTab === 'total') {
      setPageTab(total_count);
    }
    if (isTab === 'completed') {
      setPageTab(completed);
    }
    if (isTab === 'inCompleted') {
      setPageTab(incompleted);
    }
  }, [isTab, upDateloader]);
  // Pagination
  const usersPerPage = import_candidates;
  const pageCount = Math.ceil(isPageTab / usersPerPage);

  const handleCloseParse = () => {
    setParse(false);
    localStorage.setItem('bulk_loader', 'true');
  };

  const handleOpenParse = () => setParse(true);

  useEffect(() => {
    dispatch(zitaMatchProgressMiddleWare({}));
    if (data === true) {
      localStorage.setItem('bulk_loader', 'true');
    }
    if (data === false) {
      localStorage.setItem('bulk_loader', 'false');
    }
  }, [data]);

  const qaValue = qualificationFilterHelper(
    isAny,
    isBachelors,
    isDoctorate,
    isDiploma,
    isMasters,
    isOther,
  );

  const skillsOptionsList =
    formik.values.skillValue &&
    formik.values.skillValue.map((optionList: { value: string }) => {
      return optionList.value;
    });

  // Pagination Function
  const handleSetPagination = (a: number, ispagecounts?: number) => {
    setPageNumber(a);
    setTableLoader(true);
    if (isTab === 'total') {
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          page: a + 1,
          total: total_count,
          search: searchValue,
          pagecount: ispagecounts,
          work_experience: experience,
          location: location,
          skill_match: skillsOptionsList?.join(', '),
          education_level: qaValue,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
    if (isTab === 'total') {
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          page: a + 1,
          total: total_count,
          search: searchValue,
          pagecount: ispagecounts,
          work_experience: experience,
          location: location,
          skill_match: skillsOptionsList?.join(', '),
          education_level: qaValue,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
    if (isTab === 'inCompleted') {
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          page: a + 1,
          incompleted,
          search: searchValue,
          pagecount: ispagecounts,
          work_experience: experience,
          location: location,
          skill_match: skillsOptionsList?.join(', '),
          education_level: qaValue,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
    if (isTab === 'completed') {
      dispatch(
        bulkuploadedCandidatesMiddleWare({
          page: a + 1,
          completed,
          search: searchValue,
          pagecount: ispagecounts,
          work_experience: experience,
          location: location,
          skill_match: skillsOptionsList?.join(', '),
          education_level: qaValue,
        }),
      )
        .then(() => {
          getFocus('candidates__input');
          getBlur('candidates__input');
          dispatch(SubsriptionMiddleWare());
          setTableLoader(false);
        })
        .catch(() => {
          setTableLoader(false);
          Toast(ERROR_MESSAGE, 'LONG', 'error');
        });
    }
  };

  // Table Refresh Function
  const hanldeSvgRefresh = (e: any) => {
    setCandiTableLoader(true);
    formik.setFieldValue('searchValue', '');
    setTab('total');
    e.preventDefault();
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        page: 1,
        pagecount: import_candidates,
      }),
    ).then(() => {
      getFocus('candidates__input');
      setPageNumber(0);
      getBlur('candidates__input');
      setCandiTableLoader(false);
    });
    dispatch(zitaMatchProgressMiddleWare({}));
  };

  const hanldeSvgRefreshOnUpdate = (e: any) => {
    setOpenProfile(false);
    setCandiTableLoader(true);
    formik.setFieldValue('searchValue', '');
    setTab('total');
    e.preventDefault();
    dispatch(
      bulkuploadedCandidatesMiddleWare({
        page: 1,
        pagecount: import_candidates,
      }),
    ).then(() => {
      getFocus('candidates__input');
      setPageNumber(0);
      getBlur('candidates__input');
      setCandiTableLoader(false);
    });
    dispatch(zitaMatchProgressMiddleWare({}));
  };
  // Bulk Upload Parsing Function
  const hanldeParsing = () => {
    dispatch(bulkuploadedParsingMiddleWare({ parser: '1' }))
      .then((response) => {
        dispatch(
          bulkuploadedCandidatesMiddleWare({
            page: 1,
            pagecount: import_candidates,
          }),
        ).then(() => {
          setPageNumber(0);
        });
        dispatch(bulkImportMiddleWare()).then((res) => {
          setFeaturesBalance(res.payload.features_balance);
        });
        setImport(localStorage.setItem('bulk_loader', 'false'));
        localStorage.setItem('isImport', 'true');
        setParse(false);
      })
      .then(() => {
        dispatch(
          bulkuploadedCandidatesMiddleWare({
            page: 1,
            pagecount: import_candidates,
          }),
        );
      });
  };

  const manageUser = () => {
    // window.location.replace()
    window.location.replace(
      window.location.origin + '/account_setting/subscription?planFocus=focus',
    );
    // history.push('/account_setting/settings?planFocus=focus');
  };

  const handleCloseImportModal = () => {
    setImport(localStorage.setItem('isImport', 'false'));
  };

  useEffect(() => {
    setImport(localStorage.getItem('isImport'));
  });

  const handleKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };
  const update = () => {
    setverify(false);
  };
  const closemodel = () => {
    setverify(false);
    setmodel(false);
  };
  const handlefunction = () => {
    setverify(true);
    formik.setFieldValue('parser', '0');
  };
  const handlefunction1 = () => {
    setverify(true);
    formik.setFieldValue('parser', '1');
  };

  const value = emp_pool.length;
  const value1 = value > 4;
  const isBulkLoaderprocess = localStorage.getItem('bulk_loader');
  const [step5, setstep5] = useState(false);
  const [currentindex, setcurrentindex] = useState(0);
  useEffect(() => {
    dispatch(tourdataget({}));
    if (status) {
      setstep5(true);
    }
  }, [status]);
  return (
    <Flex className={styles.candidatedatabase}>
      {status && (
        <Highlighter left={'38px'} top={'263px'} text={'Add Candidates'} />
      )}
      {step5 && (
        <Tour
          process={() => {
            if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '5/9';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '5/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '5/9';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '4/8';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '4/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('create_post')
            ) {
              return '5/8';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '4/7';
            } else if (
              Permission1.includes('manage_account_settings') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '4/8';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('bulkImport_candidates')
            ) {
              return '4/7';
            } else if (
              Permission1.includes('create_post') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '4/8';
            } else if (
              Permission1.includes('bulkImport_candidates') &&
              Permission1.includes('talent_sourcing')
            ) {
              return '3/7';
            } else if (Permission1.includes('manage_account_settings')) {
              return '4/7';
            } else if (Permission1.includes('create_post')) {
              return '4/7';
            } else if (Permission1.includes('bulkImport_candidates')) {
              return '3/6';
            } else if (Permission1.includes('talent_sourcing')) {
              return '3/7';
            } else {
              return '3/6';
            }
            return null;
          }}
          skiponclick={() => {
            setstep5(false);
            setcurrentindex(5);
            sessionStorage.setItem('JobsDropdown', '0');
            sessionStorage.setItem('ViewJobsDropdown', '0');
            sessionStorage.setItem('CandidateDropdown', '0');
            sessionStorage.setItem('CommunicationDropdown', '0');
            sessionStorage.setItem('BrandDropdown', '0');
            sessionStorage.setItem('MyAccountDropdown', '1');
            sessionStorage.setItem('SourcingCandidateDropdown', '0');
            dispatch(
              tourdataget({ skip_id: 5, restart_status: false, explore: 0 }),
            ).then((res) => {
              history.push('/account_setting/profiles');
            });
          }}
          previousonclick={() => {
            setstep5(false);
            if (Permission1.includes('create_post')) {
              sessionStorage.setItem('JobsDropdown', '1');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '0');
              history.push('/jobs/create_your_job');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
            } else if (Permission1.includes('manage_account_settings')) {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '1');
              sessionStorage.setItem('MyAccountDropdown', '0');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/account_setting/build_careers_page');
            } else {
              sessionStorage.setItem('JobsDropdown', '0');
              sessionStorage.setItem('ViewJobsDropdown', '0');
              sessionStorage.setItem('CandidateDropdown', '0');
              sessionStorage.setItem('CommunicationDropdown', '0');
              sessionStorage.setItem('BrandDropdown', '0');
              sessionStorage.setItem('MyAccountDropdown', '1');
              sessionStorage.setItem('SourcingCandidateDropdown', '0');
              history.push('/account_setting/profiles');
            }
          }}
          nextonclick={() => {
            setstep5(false);
            sessionStorage.setItem('JobsDropdown', '0');
            sessionStorage.setItem('ViewJobsDropdown', '0');
            sessionStorage.setItem('CandidateDropdown', '1');
            sessionStorage.setItem('CommunicationDropdown', '0');
            sessionStorage.setItem('BrandDropdown', '0');
            sessionStorage.setItem('MyAccountDropdown', '0');
            sessionStorage.setItem('SourcingCandidateDropdown', '0');
            history.push('/bulk_import?tab=1');
          }}
          borderTop
          previous
          types={'arrowLeftTop'}
          top={'245px'}
          left={'205px'}
          // text={"Move on to 'Add Candidates'. This is the hub where talent meets opportunity. Normally, you’d add candidate profiles here, but for now, just imagine the ease of uploading resumes, and building a rich database of potential hires at your fingertips."}
          text={
            '<div style="display:contents;font-weight: bold;">Move on to \'Add Candidates\'</div>. <div style="margin-top:10px;">This is the hub where <div style="display:contents;font-weight: bold;">talents</div> meets opportunity.</div><div style="margin-top:10px;"> Normally, you’d add candidate profiles here, but for now, just imagine the ease of <div style="display:contents;font-weight: bold;">uploading resumes</div>, and building a rich database of potential hires at your fingertips.</div>'
          }
        />
      )}
      <YesOrNoModal
        title={
          <Text style={{ width: 580, marginLeft: 12 }}>
            Your available candidates limit is{' '}
            {unlimitedHelper(features_balance)}. You are importing more resumes
            than your available candidates limit. Please change your plan to
            proceed further.
          </Text>
        }
        btnLeftOnclick={() => setUpgrade(false)}
        btnRightOnclick={manageUser}
        btnLeftTitle={CANCEL}
        btnRightTitle={'Upgrade'}
        open={isUpgrade}
      />
      <ZitaMatchCandidateDrawer
        activeState={0}
        open={isProfileView}
        cancel={() => setProfileView(false)}
        jobId={'0'}
        candidateId={isCanId.toString()}
      />

      {isOpenProfile && (
        <ProfileViewModal
          filePath={isFile}
          open={isOpenProfile}
          canId={isCanId}
          openview={true}
          // cancel={() => setOpenProfile(false)}
          refreshOnUpdate={(e: any) => hanldeSvgRefreshOnUpdate(e)}
        />
      )}
      <ParsingLoadingModal
        loader
        title={'Parsing and loading resumes in background.....'}
        open={isParse}
        close={handleCloseParse}
        des={
          <Text className={styles.des}>
            {`This might take a few minutes to process the files. We'll notify you
            by email when completed. Moving away from this page will not stop
            the process.`}
          </Text>
        }
      />

      <ParsingLoadingModal
        info
        css
        title={'Resumes imported successfully....'}
        open={isImport === 'true' ? true : false}
        close={handleCloseImportModal}
        des={
          <Text>
            Your available candidates limits are{' '}
            {unlimitedHelper(features_balance)}. Please check on the{' '}
            <Text
              onClick={() => {
                setKey('2');
                handleCloseImportModal();
              }}
              color="link"
            >
              log file
            </Text>{' '}
            to get the complete import details.
          </Text>
        }
      />

      <Modal open={model}>
        <Flex
          className={verify === true ? styles.bulkmodel : styles.verifymodel}
          style={{ height: '330px' }}
        >
          <CandidateDatabase
            setmodel={setmodel}
            verifymodel={update}
            hanldeParsing={hanldeParsing}
            setParse={handleOpenParse}
            isBulkLoader={localStorage.getItem('bulk_loader')}
            setUpgrade={setUpgrade}
            candidatesLimit={features_balance}
            current_resume_count={features_balance}
          />
          {/* {verify === true ? (
            <CandidateDatabase
              setmodel={setmodel}
              verifymodel={update}
              hanldeParsing={hanldeParsing}
              setParse={handleOpenParse}
              isBulkLoader={localStorage.getItem('bulk_loader')}
              setUpgrade={setUpgrade}
              candidatesLimit={features_balance}
              Resume_parsing_count={count}
              formik={formik.values.parser}
            />
          ) : (
            <Flex >
              <Flex end onClick={() => closemodel()}>
                <SvgClose
                  width={10}
                  height={10}
                  fill={'#888888'}
                  cursor={'pointer'}
                />
               </Flex>
              <Text size={14}>
              Which parsing method would you like to use?
              </Text>
              <Flex column>
                <Flex
                  row
                  style={{  marginTop: '15px' }}
                >
                          <Flex>
                          <Card className={styles.overAll} > 
                          <Text size={14} bold style={{padding:'15px 0'}}>
                            Basic Parser
                          </Text>
                          <ul  className={styles.dot}>
                            <li>
                            A foundational parsing system designed for general use.
                            </li>
                            <li>
                            Efficient for general use but might overlook intricate details occasionally.
                            </li>
                            <li>
                            May occasionally miss out on intricate details.
                            </li>
                          </ul>
                          <Button
                            onClick={handlefunction}
                          >
                            Select
                          </Button>
                          </Card>
                          </Flex>
                          
                          <Flex style={{paddingLeft:'30px'}}>
                          <Card className={styles.overAll}  > 
                          <div className={`${styles.ribbon} ${styles.ribbonTopRight}`}><span className={styles.ribbontopright}>Paid</span></div>
                          <Text size={14} bold style={{padding:'15px 0'}}>
                           Advanced AI Parser
                          </Text>
                          <ul className={styles.dot}>
                            <li>
                            Powered by cutting-edge artificial intelligence.
                            </li>
                            <li>
                            Offers superior accuracy and can understand complex structures.
                            </li>
                            <li>
                            ecommended for precision and comprehensive data extraction.
                            </li>
                          </ul>
                          <Button
                            onClick={handlefunction1}
                          >
                            Select
                          </Button>
                          </Card>
                          </Flex>
                </Flex>
              </Flex>
            </Flex>
          )} */}
        </Flex>
      </Modal>

      <Flex>
        <CandidateDatabaseTabFilter
          isBachelors={isBachelors}
          setBachelors={setBachelors}
          isDoctorate={isDoctorate}
          setDoctorate={setDoctorate}
          isDiploma={isDiploma}
          setDiploma={setDiploma}
          isMasters={isMasters}
          setMasters={setMasters}
          isAny={isAny}
          setAny={setAny}
          isOther={isOther}
          setOther={setOther}
          experience={experience}
          setexperience={setexperience}
          location={location}
          setlocation={setlocation}
          skillsOptionsList={skillsOptionsList}
          qaValue={qaValue}
          import_candidates={import_candidates}
          formik={formik}
          ispagecount={ispagecount}
          isBulkTab={isBulkTab}
          candidate_location={candidate_location}
          setCandiTableLoader={setCandiTableLoader}
        />
      </Flex>
      <Flex row between>
        <Flex row center className={styles.inputConatiner}>
          <InputText
            className={styles.inputWidth}
            inputConatinerClass={styles.inputStyle}
            placeholder={'Search candidates by name or email'}
            value={searchValue}
            onChange={searchHandleChange}
            id={'candidates__input'}
            actionRight={() => (
              <label style={{ margin: 0, marginTop: '3px' }}>
                <SvgSearch width={14} height={14} />
              </label>
            )}
            onKeyPress={handleKeyPress}
          />
          <Button
            disabled={searchValue === '' ? true : false}
            onClick={handleSubmit}
          >
            {/* Find Candidates */}
            Search
          </Button>
        </Flex>
        <Flex className={styles.inputConatiner}>
          {isBulkLoaderprocess === 'true' ? (
            <Flex row>
              <Loader size="medium" withOutOverlay />
              <Text color="gray" style={{ marginLeft: 16 }}>
                Processing...
              </Text>
            </Flex>
          ) : (
            <>
              {current_resume_count !== 0 ? (
                <Button onClick={handlechange} style={{ marginRight: '15px' }}>
                  Upload Resumes
                </Button>
              ) : (
                <Button
                  onClick={() => setopensubcription(true)}
                  style={{ marginRight: '15px' }}
                >
                  <Flex row>
                    <Flex style={{ cursor: 'pointer' }}>
                      <Text color="white"> Upload Resumes</Text>
                    </Flex>
                    <Flex
                      marginLeft={5}
                      marginTop={1}
                      style={{ cursor: 'pointer' }}
                    >
                      <SvgSubcriptioncrown height={14} width={14} fill="" />
                    </Flex>
                  </Flex>
                </Button>
              )}
            </>
          )}
        </Flex>
      </Flex>

      <Flex row center className={styles.filterStyle}>
        <Flex
          row
          center
          className={styles.marginLeft}
          title="Filter Total Candidates"
        >
          {total_count === 0 ? (
            <Text bold={isTab === 'total'} className={styles.linkSpaceDefault}>
              <Totalcount
                name="Total Imported Candidates"
                numbers={total_count}
                click={total_count === 0 ? false : true}
              />
            </Text>
          ) : (
            <Text
              onClick={() => {
                handleTotal();
                setTab('total');
              }}
              bold={isTab === 'total'}
              className={styles.linkSpace}
              color={'link'}
              style={{ cursor: 'pointer' }}
            >
              <Totalcount
                name="Total Imported Candidates"
                numbers={total_count}
                click={total_count === 0 ? false : true}
              />
            </Text>
          )}
        </Flex>
        {total_count !== 0 && (
          <>
            <Flex
              row
              center
              className={styles.marginLeft}
              title="Filter Completed Profiles"
            >
              {completed === 0 ? (
                <Text
                  bold={isTab === 'completed'}
                  className={styles.linkSpaceDefault}
                  style={{ cursor: 'pointer' }}
                >
                  <Totalcount
                    name="Completed Profiles"
                    numbers={completed}
                    click
                  />
                </Text>
              ) : (
                <Text
                  onClick={() => {
                    handleCompleted();
                    setTab('completed');
                  }}
                  bold={isTab === 'completed'}
                  color={'link'}
                  className={styles.linkSpace}
                  style={{ cursor: 'pointer' }}
                >
                  <Totalcount
                    name="Completed Profiles"
                    numbers={completed}
                    click
                  />
                </Text>
              )}
            </Flex>

            <Flex
              row
              center
              className={styles.inComplete}
              title="Filter Incomplete Profiles"
            >
              {incompleted === 0 ? (
                <Text
                  bold={isTab === 'inCompleted'}
                  className={styles.linkSpaceDefault}
                  style={{ cursor: 'pointer' }}
                >
                  <Totalcount
                    name="Incomplete Profiles"
                    numbers={incompleted}
                    click={incompleted === 0 ? false : true}
                  />
                </Text>
              ) : (
                <Text
                  color={'link'}
                  onClick={() => {
                    handleInCompeleted();
                    setTab('inCompleted');
                  }}
                  bold={isTab === 'inCompleted'}
                  className={styles.linkSpace}
                  style={{ cursor: 'pointer' }}
                >
                  <Totalcount
                    name="Incomplete Profiles"
                    numbers={incompleted}
                    click
                  />
                </Text>
              )}
            </Flex>
          </>
        )}
        {total_count > 0 && (
          <CustomTooltip title={'Refresh table'}>
            <div
              tabIndex={-1}
              role={'button'}
              onKeyPress={() => {}}
              className={styles.svgRefresh}
              onClick={(e) => {
                hanldeSvgRefresh(e);
              }}
            >
              <SvgRefresh />
            </div>
          </CustomTooltip>
        )}
      </Flex>
      {isCandiTableLoader ? (
        <Flex center middle>
          <Loader size={'medium'} />
        </Flex>
      ) : emp_pool?.length === 0 &&
        !params.includes('search') &&
        !params.includes(
          'education_level',
          'work_experience',
          'location',
          'skill_match',
        ) ? (
        <>
          <Flex
            style={{ display: 'flex' }}
            // height={window.innerHeight - 355}
            // style={{ opacity: 0.5, pointerEvents: 'none' }}
          >
            {/* <Flex>
                <Text color='placeholder' size={13} align='center'>
                  Your Talent pool is Empty
                </Text>
                <Text color='placeholder' size={13} align='center'>
                  Import candidates to build your database
                </Text>
              </Flex> */}
            {!isSampleData ? (
              <>
                <Flex style={{ opacity: 0.5, pointerEvents: 'none' }}>
                  <Tabel columns={columnsCandi} />
                </Flex>
                <Flex middle center height={componentHeight}>
                  <Flex center>
                    {/* <Flex center>
                        <Text className={styles.postyet1} size={13}>
                          No Candidates Imported - Yet !
                        </Text>
                      </Flex> */}
                    <Flex center>
                      <Text
                        className={styles.postyet2}
                        size={13}
                        color="placeholder"
                      >
                        Your Talent pool is Empty
                      </Text>
                    </Flex>
                    <Flex center>
                      <Text
                        className={styles.postyet3}
                        size={13}
                        color="placeholder"
                      >
                        Import candidates to build your database.
                      </Text>
                    </Flex>
                    <Flex row middle>
                      <Text style={{ marginRight: '4px' }} color="placeholder">
                        To refer some sample Candidate details
                      </Text>
                      <Text
                        color="link"
                        bold
                        onClick={() => setSampleData(true)}
                      >
                        Click here
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            ) : (
              <>
                <Flex style={{ opacity: 0.5, pointerEvents: 'none' }}>
                  <Tabel dataSource={Sampledata} columns={columnsCandi} />
                </Flex>
                <Flex style={{ position: 'absolute', left: '46%', top: '67%' }}>
                  <Text color="link" bold onClick={() => setSampleData(false)}>
                    Go back
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </>
      ) : (
        <Flex flex={1}>
          <Tabel
            empty={
              (params.includes('search') && 'No candidate found') ||
              (params.includes('education_level') &&
                params.includes('work_experience') &&
                params.includes('location') &&
                params.includes('skill_match') &&
                'No candidate found')
            }
            dataSource={emp_pool}
            columns={columns}
            // isLoader={isTableLoader}
            pageCount={pageCount}
            pageNumber={pageNumber}
            handleSetPagination={handleSetPagination}
            isCandiTableLoader={isCandiTableLoader}
            isPageTab={isPageTab}
            setpagecount={setpagecount}
            only_pagination={true}
            pagination_count={ispagecount}
          />
        </Flex>
      )}

      {isopensubcription && (
        <>
          <SubscriptionModal
            openmodel={isopensubcription}
            setopensubcription={setopensubcription}
            addon_name={['AI Resume Parsing Credits']}
            differece_key={['AI_matching']}
            purpose={
              <div>
                <strong>
                  <text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                  >
                    Heads Up!
                  </text>
                </strong>
                <strong>AI Resume Parsing Limit Reached:</strong> To continue
                viewing applicants, you must &apos;Upgrade your plan&apos; or
                &apos;Buy additional AI Resume Parsing Credits add-on&apos;.
              </div>
            }
            purpose_freetrial={
              <div>
                <strong>
                  <text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                  >
                    Heads Up!
                  </text>
                </strong>
                <strong>AI Resume Parsing Limit Reached:</strong> To continue
                viewing applicants, you must &apos;Upgrade your plan&apos;.
              </div>
            }
            purpose_addon={
              <div>
                <strong>
                  <text
                    style={{
                      color: '#2E6ADD',
                      marginRight: '3px',
                      fontSize: '13px',
                    }}
                  >
                    Heads Up!
                  </text>
                </strong>
                <strong>AI Resume Parsing Limit Reached:</strong> To continue
                viewing applicants, you must &apos;Buy additional AI Resume
                Parsing Credits add-on&apos;.
              </div>
            }
          />
        </>
      )}
    </Flex>
  );
};

export default CandidateDatabaseTab;
